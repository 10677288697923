import React from "react"
import { Layout, Parallax } from "@components/layout"
import { SingleTask } from "@components/standard"
import { AnimatedLink, AnimatedSection, ItemSlider } from "@components/shared"
import html from "./img/icons8-html-5.svg"
import css from "./img/icons8-css3.svg"
import js from "./img/icons8-javascript.svg"
import ts from "./img/icons8-typescript.svg"
import react from "./img/icons8-react-native.svg"
import redux from "./img/icons8-redux.svg"
import jest from "./img/jest-icon.svg"

import { Breadcrumbs, Reviews, ResizedImage as Image } from "@components/shared"

import {
  tasks__wave,
  tasks__parallax,
  tasks__header,
  tasks__content__header,
  tasks__content,
  tasks__opinionform,
  tasks__reasons__wrapper,
  tasks__reasons__title,
  tasks__reasons__list,
  tasks__opinions,
  tasks__opinions__wrapp,
  tasks__levels,
  tasks__levels__wrapp,
  tasks__levels__newbie,
  tasks__levels__junior,
  tasks__levels__smartJunior,
  tasks__levels__btn,
  tasks__levels__icons,
  tasks__levels__free,
  tasks__public,
  icon,
  icon__wrapp,
  tasks__free__btn,
} from "./styles/tasks.module.scss"

import {
  reviews__list,
  reviews__list__img,
  reviews__list__item,
  reviews__list__name,
  reviews__list__text,
} from "./styles/reviews.module.scss"

const reviews = [
  {
    author: "Mateusz",
    content:
      "Ciekawe zadania, dzięki którym można utrwalić wiedzę, a przy tym poznać coś nowego ze świata JSa i Reacta",
  },
  {
    author: "Monika",
    content:
      "Ćwiczę z Przemkiem już jakiś czas zadania i czuję, że dopiero teraz zaczynam uczyć się dobrze kodować",
  },
  {
    author: "Krzysztof",
    content:
      "Fajna inicjatywa 💪🏻 i ogromna ilość zadań. No to biorę się za kodowanie :)",
  },
  {
    author: "Rafał",
    content:
      "Ponad 3 lata jestem Javascript Devem, a do niektórych zadań, żeby podejść to musiałbym porządnie się zastanowić jak je zrobić. Świetny pomysł i wykonanie!",
  },
  {
    author: "Dorota",
    content:
      "Ale trudne te zadania, uświadomiłam sobie jak niewiele umiem i jak dużo pracy jeszcze mnie czeka",
  },
  {
    author: "Daniel",
    content:
      "Polecam tego allegrowicza! Wymagające zadania sprawdzą Twoją wiedzę i pozwolą doszlifować umiejętności",
  },
  {
    author: "Michał",
    content:
      "Super inicjatywa. I ceny widzę też bardzo przystępne. Zadania ciekawe, bardzo fajne oderwanie od codziennych zajec na projekcie",
  },
]

const ReviewSlide = ({ author, content }) => {
  return (
    <div className={reviews__list__item}>
      <div className={reviews__list__name}>{author}</div>
      <AnimatedSection className={reviews__list__text}>
        {content}
      </AnimatedSection>
      <div className={reviews__list__img}></div>
    </div>
  )
}

const Opinions = () => {
  return (
    <article className={reviews__list}>
      <ItemSlider
        items={reviews}
        Slide={ReviewSlide}
        observeValue={(item) => item.author}
      />
    </article>
  )
}

const Wave = () => {
  return (
    <div className={tasks__wave}>
      <svg viewBox="0 0 1200.88 400" width="100%" height="100%">
        <path
          d="M1200,400c-134.35-137.81-310-62.19-769.68-15.42S0,0,0,0V400Z"
          transform="translate(0.88)"
          fill="#fff"
        />
      </svg>
    </div>
  )
}

const Tasks = ({ pageContext }) => {
  const { page, products } = pageContext
  const _products = products.reverse()

  return (
    <Layout {...page}>
      <div className={tasks__parallax}>
        <Parallax height={400}>
          <h4 className={tasks__header}>
            Tworzę zbiory hardocoreowych zadań dla Frontendowców
          </h4>
          <Wave />
        </Parallax>
      </div>

      <Breadcrumbs
        elements={[
          {
            label: page.title,
            url: `/${page.slug}/`,
          },
        ]}
      />
      <div className={tasks__content}>
        <div className={tasks__content__header}>
          <h5>O co tu chodzi:</h5>

          <p>
            To inicjatywa skierowana do społeczności młodych programistów, dla
            motywacji newbie i juniorów, aby regularnie rozwijali swoje
            umiejętności na zadaniach, które stanowią dla nich wyzwanie i zmuszą
            do zdobycia nowej wiedzy.
          </p>
          <p>
            Z doświadczenia wiem, że programowania nie da się nauczyć przez
            przyglądanie. Żeby tego dokonać trzeba poświęcić wiele godzin na
            kodowanie, a żeby robić to efektywnie potrzebujesz do tego
            właściwych zadań treningowych.
          </p>
        </div>

        <h5>Dla kogo są zbiory:</h5>
        <ul>
          <li>
            Nie wiesz, w którą stronę rozwijać się po kursie programowania
          </li>
          <li>
            Szukasz wyzwań programistycznych na podstawie praktycznych tasków i
            zadań rekrutacyjnych
          </li>
          <li>
            Dla samouków na różnych poziomach rozwoju, chcących tanio zapewnić
            sobie masę godzin ciekawej praktyki rozwiązując różne problemy
          </li>
          <li>
            Jesteś już Junior Frontend Developerem, ale w Twojej pracy nie masz
            tasków pozwalających Ci się rozwijać
          </li>
        </ul>
        <div className={tasks__reasons__wrapper}>
          <h3 className={tasks__reasons__title}>
            7 SOLIDNYCH POWODÓW, DLACZEGO WARTO mieć W SWOJEJ PROGRAMISTYCZNEJ
            KOLEKCJI ZBIORY ZADAŃ Gladiatorów Javascriptu
          </h3>

          <ol className={tasks__reasons__list}>
            <li>
              Każdy zbiór to już ponad 200h intensywnej pracy głową, a
              regularnie dorzucam do nich kolejne zadania bądź inspirację
            </li>
            <li>
              Ambitne zadania wymagające zdobycia nowej wiedzy, bo ciągły rozwój
              i kodowanie jako rzemiosło to podstawa w szybko zmieniającej się
              branży IT
            </li>
            <li>
              Zadania są pisane w JS i w React, ale większość problemów jest
              uniwersalna i można podjąć się rozwiązania za pomocą Angulara czy
              Vue. Większość zadania z pisania logiki z JS z powodzeniem
              odtworzysz w Pythonie czy PHPie
            </li>
            <li>
              Idealne ćwiczenia praktyczne dla osób po kursach i bootcampach, a
              także tych co już ubiegają się o pierwszą pracę
            </li>
            <li>Wiele z życia i pracy wziętych problemów do rozwiązania</li>
            <li>
              W zbiorach są inspiracje na rozbudowę portfolio o nowe, ciekawe
              projekty. Aby się wyróżnić na rynku i mieć o czym rozmawiać z HRem
              i technicznymi musisz mieć ze 2 ambitniejsze projekty, o których
              opowiesz na rozmowie rekrutacyjnej
            </li>
            <li>
              Wspierasz moją inicjatywę dzięki czemu mogę poświecać maksimum
              czas na TUNING polskich niedoświadczonych programistów Javascript,
              aby mogli zdobywać rynki na całym świecie
            </li>
          </ol>
        </div>

        <div className={tasks__opinions__wrapp}>
          <h5>
            Opinie osób, które wsparły inicjatywę i mają swoje zdanie na temat
            moich zbiorów
          </h5>
          <div className={tasks__opinions}>
            <Opinions />
          </div>
          <AnimatedSection className={tasks__opinionform}>
            <Reviews />
          </AnimatedSection>
        </div>
        <div className={tasks__levels__wrapp}>
          <h2>Zbiory zadań Gladiatorów Javascriptu</h2>
          <p>
            Mojej inicjatywy stworzyłem dla społeczności 3 zbiory zadań dla
            różnych poziomów doświadczenia i wspierając inicjatywę możesz
            uzyskać do nich dożywotnią subskrypcję
          </p>
          <div className={tasks__levels}>
            {/* ======================================================================== */}

            <div className={tasks__levels__newbie}>
              <h4>
                Rozwijające i utrwalające zadania z HTML, CSS i JAVASCRIPT dla
                newbie developera
              </h4>
              <div className={icon__wrapp}>
                <div className={icon}></div>
                <div className={tasks__levels__icons}>
                  <img src={html} alt="" />
                  <img src={css} alt="" />
                  <img src={js} alt="" />
                </div>
              </div>
              <div>
                Dla tych, którzy ukończyli jakikolwiek kurs i chcą się przekonać
                jak dużo dał im kurs. Obowiązkowa pozycja dla osób, które niskim
                kosztem chce mieć ogrom zadań praktycznych w rozwoju w kierunku
                pierwszej pracy. Pierwsza i najłatwiejsza część z 3 częściowego
                zestawu zbiorów...
              </div>

              <AnimatedLink
                to="/shop/products/rozwijajace-i-utrwalajace-zadania-z-html-css-i-javascript/"
                className={tasks__levels__btn}
              >
                Poczytaj więcej o zbiorze
              </AnimatedLink>
            </div>
            {/* ======================================================================== */}

            <div className={tasks__levels__junior}>
              <h4>Ambitne i wymagające zadania z JAVASCRIPT i React</h4>
              <div className={icon__wrapp}>
                <div className={icon}></div>
                <div className={tasks__levels__icons}>
                  <img src={js} alt="" />
                  <img src={ts} alt="" />
                  <img src={react} alt="" />
                  <img src={redux} alt="" />
                </div>
              </div>
              <div>
                Dla tych, którzy są gotowi na rynek, ale wiedzą, że muszą się
                dalej uczyć bardziej zaawansowanej wiedzy z programowania.
                Obowiązkowa pozycja dla osób, które niskim kosztem chce mieć
                ogrom zadań praktycznych w rozwoju w kierunku pierwszej pracy
                bądź poznania Reacta lepiej i bardziej praktycznie. Druga część
                z 3 częściowego zestawu zbiorów...
              </div>

              <AnimatedLink
                to="/shop/products/ambitne-i-wymagajace-zadania-z-js-i-react/"
                className={tasks__levels__btn}
              >
                Poczytaj więcej o zbiorze
              </AnimatedLink>
            </div>

            {/* ======================================================================== */}

            <div className={tasks__levels__smartJunior}>
              <h4>
                Nieprzewidziane i sprawiające problemy testy JAVASCRIPT i React
              </h4>
              <div className={icon__wrapp}>
                <div className={icon}></div>
                <div className={tasks__levels__icons}>
                  <img src={js} alt="" />
                  <img src={react} alt="" />
                  <img src={redux} alt="" />
                  <img src={jest} alt="" />
                </div>
              </div>
              <div>
                Dla tych, którzy codziennie pracują jako programiści, ale
                wiedzą, że muszą się dalej uczyć bardziej zaawansowanej wiedzy z
                programowania. Dedykowany zbiór dla osób, które chcą uczyć się
                testowania. Obowiązkowa pozycja dla osób, które niskim kosztem
                chce mieć ogrom zadań praktycznych w rozwoju w kierunku awansu w
                pracy. Trzecia część z 3 częściowego zestawu zbiorów...
              </div>

              <AnimatedLink
                to="/shop/products/nieprzewidziane-i-sprawiajace-problemy-testy-js-i-react"
                className={tasks__levels__btn}
              >
                Poczytaj więcej o zbiorze
              </AnimatedLink>
            </div>
            {/* ======================================================================== */}
            <div className={tasks__levels__free}>
              <h4>Publiczny ZBIÓR ZADAŃ Gladiatorów Javascriptu</h4>
              <div className={icon__wrapp}>
                <div className={icon}></div>
                <div className={tasks__levels__icons}>
                  <img src={html} alt="" />
                  <img src={css} alt="" />
                  <img src={js} alt="" />
                  <img src={react} alt="" />
                  <img src={redux} alt="" />
                  <img src={jest} alt="" />
                </div>
              </div>
              <p>
                Dla dobra społeczności buduję publiczne repo ze zbiorem zadań z
                dziedziny frontendu, jeśli nie śmierdzisz groszem, a chcesz
                zostać programistą/programistką to ja nie będę Ci tego utrudniać
                z powodów finansowych. Powodzenia!
                <br />W zbiorze znajdują się zadania z bardziej zaawansowanego
                CSS, JS, Reacta, z pisania testów, zarówno z programowania
                funkcyjnego jak i obiektowego, więc każdy znajdzie coś dla
                siebie.
              </p>

              <AnimatedLink
                to="https://github.com/Przemocny/zbior-zadan-html-css-js-react"
                className={tasks__free__btn}
              >
                Zobacz zbiór na Githubie
              </AnimatedLink>
            </div>
          </div>
        </div>

        {/* {_products.map((el, k) => {
					return <SingleTask {...el} key={k} />
				})} */}
      </div>
    </Layout>
  )
}

export default Tasks
